.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-table {
  border: 0px solid #1C6EA4;
  background-color: #EEEEEE;
  width: 80%;
  text-align: left;
  border-collapse: collapse;
}

.App-table td, .App-table th {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
  color: #000000;
}

.App-table thead {
  background: #8BC34A;
  text-align: center;
}

.first_col {
  text-align: left;
}

.App-table tbody {
  text-align: right;
}


.App-table thead th {
  font-size: 17px;
  font-weight: bold;
  color: #FFFFFF;
}

.App-table tr:nth-child(even) {
  background: #C5E1A5;
}

.App-table td {
  font-size: 15px;
}

.App-table thead {
  border-top: 0;
  background: #8BC34A;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
